<template>
  <div class="weather" v-show="!showId">
    <div class="text">
      <!-- <img
        class="img"
        v-if="weatherObj.text === '雪'"
        :src="$imgOnlineUrl + '/index/weather/icon5.png'"
      />
      <img
        class="img"
        v-if="weatherObj.text === '雾'"
        :src="$imgOnlineUrl + '/index/weather/icon9.png'"
      />  -->
      <img
        class="img"
        :src="
          weatherObj.icon
            ? $imgOnlineUrl + '/index/weather/' + weatherObj.icon + '.png'
            : ''
        "
      />
      <p class="p">
        <span>{{ weatherObj.text }}</span
        ><span>{{ weatherObj.temp }}℃</span>
      </p>
    </div>
    <div class="other">
      <div class="otherItem">
        <img :src="$imgOnlineUrl + '/index/weather/icon6.png'" />
        <div class="infoTitle">气压</div>
        <div>{{ weatherObj.pressure }}</div>
      </div>
      <div class="otherItem">
        <img :src="$imgOnlineUrl + '/index/weather/icon7.png'" />
        <div class="infoTitle">风向</div>
        <div>{{ weatherObj.windDir }}</div>
      </div>
      <div class="otherItem">
        <img :src="$imgOnlineUrl + '/index/weather/icon8.png'" />
        <div class="infoTitle">湿度</div>
        <div>{{ weatherObj.humidity }}%</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getWeatherApi } from "@/request/api";
import { mapState } from "vuex";

export default {
  props: {
    showId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      weatherObj: {},
    };
  },
  computed: {
    ...mapState({
      // position: (state) => state.user.position,
      farmInfo: (state) => state.user.farmInfo,
    }),
  },
  created() {
    this.getWeatherApi();
  },
  methods: {
    async getWeatherApi() {
      const { code, data } = await getWeatherApi({
        location: this.farmInfo.position,
      });
      if (code === 200) {
        this.weatherObj = data.now;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.weather {
  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    .img {
      width: 75px;
      height: 75px;
      margin-bottom: 10px;
    }
    .p {
      span {
        color: #3e90e5;
        font-size: 18px;
        font-weight: bold;
        &:last-of-type {
          margin-left: 16px;
          color: #fff;
          font-weight: normal;
        }
      }
    }
  }
  .other {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .otherItem {
      width: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 40px;
        height: 40px;
        margin-bottom: 18px;
      }
      .infoTitle {
        font-size: 14px;
        margin-bottom: 14px;
        font-weight: normal;
      }
      div {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
